import cx from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import Icon, { IconSize, Icons } from '@app/common/components/Icon';
import ToggleButtonV2, { LabelPosition } from '@app/common/components/ToggleButtonV2';
import Tooltip from '@app/common/components/tooltips/Tooltip';
import ContactSearch from '@app/components/search/ContactSearch.js';
import { DOCUMENT_CUSTOM } from '@app/config';
import useComponentId from '@app/hooks/useComponentId';
import { ContactSearchResult } from '@app/types/ContactSearch';
import { UploadADoc, Client as UploadADocClient } from '@app/types/UploadADoc';
import { isDocumentStatusDraft } from '@app/utils/generalUtils.js';
import { FormPhone } from '../../../../components/form/FormPhone';
import TextInputField from '../buildADoc/components/TextInput';
import { RecipientSummary, RecipientSummaryContent } from './RecipientSummary';
import ReorderableAccordion from './ReorderableAccordion';
import { confirmAlert } from 'react-confirm-alert';
import Note, { NoteType } from '@app/common/components/Note';
import ConfirmAlert from './ConfirmAlert';
import { reloadClientAnnotations } from '@app/containers/PdfViewer/utils';
import { UploadedDocumentState } from '@app/containers/PdfViewer/components/useUploadedDocumentState';
import { setCurrentDocument } from '../../../../actions/document.js';
import useToast from '@app/hooks/useToast';
import { ToastTypes } from '@app/common/components/Toast';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck.js';

import styles from './RecipientSectionV2.module.scss';
import { getErrorMessageForToast } from '@app/utils/errorUtils';
import { formatPhoneNumber } from '@app/utils/phoneUtils';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { UAD_CONNECT_ID } from '@app/constants/featureFlags';

const MAX_RECIPIENTS = 4;

type Client = Partial<Pick<UploadADocClient, 'name' | 'email' | 'phone' | 'source' | 'id'>>;

type SequentialSigningToggleProps = {
    isDisabled: boolean;
    hasSignerPlaceholders?: boolean;
};

const SequentialSigningToggle: React.FC<SequentialSigningToggleProps> = ({ isDisabled, hasSignerPlaceholders }) => {
    const id = useComponentId();

    return (
        <div className={styles.sequentialSigning}>
            <ToggleButtonV2
                name="enforceSequentialSigning"
                labelPosition={LabelPosition.End}
                label="Sign documents in Sequential Order"
                disabled={isDisabled || hasSignerPlaceholders}
                switchTooltip={
                    hasSignerPlaceholders
                        ? 'Sequential Signing Order has been enforced because Custom Recipient Placeholders are being used.'
                        : undefined
                }
            />
            <Icon
                icon={Icons.INFO}
                size={IconSize.SMALL}
                className={styles.infoIcon}
                data-tip="Your recipients listed below will be receiving and signing their documents one at a time depending on the order."
                data-for={`info-tooltip-${id}`}
            />
            <Tooltip id={`info-tooltip-${id}`} effect="solid" place="bottom" />
        </div>
    );
};

type RecipientSectionV2Props = {
    document?: UploadADoc;
    isReadOnly?: boolean;
    isSequentialSigningEnabled: boolean;
    shouldDisableAddingRecipients: boolean;
    hasSignerPlaceholders?: boolean;
    showPhoneInput: boolean;
    isReorderEnabled: boolean;
    onReorderClients: (activeIndex: number, overIndex: number) => void;
    documentsCount: number;
    setIsSubmitting: (isSubmitting: boolean) => void;
    uploadedDocumentStateItems: UploadedDocumentState[];
    isTemplate: boolean;
};

export const RecipientSectionV2: React.FC<RecipientSectionV2Props> = ({
    document,
    isReadOnly = false,
    isSequentialSigningEnabled,
    shouldDisableAddingRecipients,
    hasSignerPlaceholders,
    showPhoneInput,
    isReorderEnabled,
    onReorderClients,
    documentsCount,
    setIsSubmitting,
    uploadedDocumentStateItems,
    isTemplate
}) => {
    const isConnectIdEnabled = useFeatureFlag(UAD_CONNECT_ID);

    const componentId = useComponentId();
    const dispatch = useDispatch();
    const form = useForm();
    const { addNewToast } = useToast();
    const { errors } = form.getState();
    const values = form.getState().values;

    // This is used to collapse the last client when a new client is added from contact search
    const [collapseLastClient, setCollapseLastClient] = React.useState(false);
    const [deletingClientIndex, setDeletingClientIndex] = React.useState<number | null>(null);

    const clients = useFieldArray<Client>('clients');

    const clientsLength = clients.fields.length ?? 0;
    const isClientsEmpty =
        clientsLength === 0 ||
        (clientsLength === 1 &&
            !clients.fields.value[0]?.name &&
            !clients.fields.value[0]?.email &&
            !clients.fields.value[0]?.phone);

    const addSearchRecipient = (result: ContactSearchResult) => {
        const lastClient = clientsLength > 0 ? clients.fields.value[clientsLength - 1] : null;
        const isLastClientEmpty = lastClient && !lastClient.name && !lastClient.email && !lastClient.phone;

        const phone = formatPhoneNumber(result.mobile || result.phone || '');

        if (isLastClientEmpty) {
            clients.fields.update(clientsLength - 1, {
                name: result.fullName,
                email: result.email,
                phone
            });
        } else {
            clients.fields.push({
                name: result.fullName,
                email: result.email,
                phone
            });
        }
        setCollapseLastClient(true);
    };

    const handleSearchSelect = (result: ContactSearchResult) => {
        if (hasSignerPlaceholders && !isClientsEmpty && !values.enforceSequentialSigning) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ConfirmAlert
                            title="Signing Order will be enabled"
                            message={
                                <p>
                                    <strong>{clients?.fields?.value[0]?.name || 'Recipient 1'}</strong> will be required
                                    to fill in the assigned <strong>Custom Info Placeholders</strong> when the document
                                    is sent for signing.
                                </p>
                            }
                            confirmText={`Yes, Assign to ${clients?.fields?.value[0]?.name || 'Recipient 1'}`}
                            cancelText="Cancel"
                            onConfirm={() => {
                                addSearchRecipient(result);
                                form.change('enforceSequentialSigning', true);
                                onClose();
                            }}
                            onCancel={onClose}
                        />
                    );
                }
            });
        } else {
            addSearchRecipient(result);
        }
    };

    const deleteClient = async (index: number, afterDelete?: () => void) => {
        const clientToDelete = clients.fields.value[index];

        // If the client has an id it has been saved to the database and we need to remove it from the document
        if (document && clientToDelete.id) {
            setDeletingClientIndex(index);
            const customDocumentUrl = `api/document/custom-document/${document.id}`;
            setIsSubmitting(true);
            try {
                const response = await axios.post(`${customDocumentUrl}/remove-client/${clientToDelete.id}`);
                if (response.data.customDocument) {
                    const updatedDocument = response.data.customDocument as UploadADoc;
                    reloadClientAnnotations(updatedDocument.uploadedDocuments, uploadedDocumentStateItems);
                    dispatch(
                        setCurrentDocument({
                            ...document,
                            uploadedDocuments: updatedDocument.uploadedDocuments
                        })
                    );
                    clients.fields.remove(index);
                    afterDelete?.();
                }
                setIsSubmitting(false);
                setDeletingClientIndex(null);
            } catch (error) {
                addNewToast(getErrorMessageForToast('Unable to delete recipient.'), ToastTypes.ERROR);
                setIsSubmitting(false);
                setDeletingClientIndex(null);
                return;
            }
        } else {
            clients.fields.remove(index);
            afterDelete?.();
        }

        if (clientsLength === 2 && values.enforceSequentialSigning) {
            form.change('enforceSequentialSigning', false);
        }
    };

    return (
        <div className={styles.container}>
            {document && isDocumentStatusDraft(document.status) && (
                <ContactSearch
                    onSelect={handleSearchSelect}
                    allowStaffSearch={true}
                    disabled={shouldDisableAddingRecipients || clientsLength >= MAX_RECIPIENTS}
                    contactType={DOCUMENT_CUSTOM}
                    toolTipText={
                        shouldDisableAddingRecipients
                            ? 'You have used placeholders for the recipient to enter information. This feature is currently only available with 1 recipient only.'
                            : 'Type to search all contacts imported'
                    }
                />
            )}
            {hasSignerPlaceholders && clientsLength > 1 && (
                <Note
                    title={`Custom Info Placeholders have been assigned to ${clients?.fields?.value[0]?.name || 'Recipient 1'}`}
                    type={NoteType.WARNING2}
                    className={styles.readOnlyWarning}
                >
                    <strong>Sequential Signing Order</strong> has now been enabled, meaning{' '}
                    <strong>{clients?.fields?.value[0]?.name || 'Recipient 1'}</strong> must complete the required
                    information before the other Recipients can review the document{documentsCount > 1 ? 's' : ''}. To
                    disable this, please reassign the Custom Info Placeholders back to <strong>Sender</strong>
                </Note>
            )}
            {isReadOnly ? (
                <>
                    {document?.enforceSequentialSigning && <SequentialSigningToggle isDisabled={isReadOnly} />}
                    {clients.fields.value.map((client, index) => {
                        const documentClient = document?.client?.clients[index];
                        const currentSigningOrder = document?.client?.currentSigningClientOrder;
                        const clientSigningOrder = documentClient?.signingOrder;

                        const isClientBlockedFromSigning =
                            document?.enforceSequentialSigning &&
                            currentSigningOrder &&
                            clientSigningOrder &&
                            currentSigningOrder < clientSigningOrder;
                        return (
                            <RecipientSummary
                                key={index}
                                client={{ ...client, isSigned: documentClient?.isSigned }}
                                index={index}
                                showCopyLink={!document?.isLinkExpired && !isClientBlockedFromSigning}
                                document={document}
                            />
                        );
                    })}
                </>
            ) : (
                <ReorderableAccordion<Client>
                    itemData={clients.fields.value}
                    onReorderItems={(activeIndex, overIndex) => {
                        if (hasSignerPlaceholders && (activeIndex === 0 || overIndex === 0)) {
                            const newPrimaryRecipientIndex = activeIndex === 0 ? 1 : activeIndex;
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <ConfirmAlert
                                            title="Are you sure?"
                                            message={
                                                <>
                                                    <p>
                                                        <strong>
                                                            Custom Info Placeholders are currently assigned to{' '}
                                                            {clients?.fields?.value[0]?.name || 'Recipient 1'}.
                                                        </strong>
                                                    </p>
                                                    <p>
                                                        If the signing order is modified, the Placeholders will be
                                                        assigned to{' '}
                                                        <strong>
                                                            {clients?.fields?.value[newPrimaryRecipientIndex]?.name ||
                                                                'the new Recipient 1'}
                                                        </strong>
                                                        .
                                                    </p>
                                                </>
                                            }
                                            confirmText="Yes, Continue with reordering"
                                            cancelText="No, Keep existing"
                                            onConfirm={() => {
                                                onReorderClients(activeIndex, overIndex);
                                                clients.fields.move(activeIndex, overIndex);
                                                onClose();
                                            }}
                                            onCancel={onClose}
                                        />
                                    );
                                }
                            });
                        } else {
                            onReorderClients(activeIndex, overIndex);
                            clients.fields.move(activeIndex, overIndex);
                        }
                    }}
                    onAddItem={callback => {
                        if (hasSignerPlaceholders && clientsLength > 0 && !values.enforceSequentialSigning) {
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <ConfirmAlert
                                            title="Signing Order will be enabled"
                                            message={
                                                <p>
                                                    <strong>{clients?.fields?.value[0]?.name || 'Recipient 1'}</strong>{' '}
                                                    will be required to fill in the assigned{' '}
                                                    <strong>Custom Info Placeholders</strong> when the document is sent
                                                    for signing.
                                                </p>
                                            }
                                            confirmText={`Yes, Assign to ${clients?.fields?.value[0]?.name || 'Recipient 1'}`}
                                            cancelText="Cancel"
                                            onConfirm={() => {
                                                clients.fields.push({});
                                                form.change('enforceSequentialSigning', true);
                                                callback?.();
                                                onClose();
                                            }}
                                            onCancel={onClose}
                                        />
                                    );
                                }
                            });
                        } else {
                            clients.fields.push({});
                            callback?.();
                        }
                    }}
                    canAddItem={!shouldDisableAddingRecipients && clientsLength < MAX_RECIPIENTS}
                    disabledAddItemTooltip={
                        shouldDisableAddingRecipients
                            ? 'You have used placeholders for the recipient to enter information. This feature is currently only available with 1 recipient.'
                            : undefined
                    }
                    onDeleteItem={async (index, callback) => {
                        if (hasSignerPlaceholders && index === 0) {
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <ConfirmAlert
                                            title="Are you sure?"
                                            message={
                                                <>
                                                    <p>
                                                        <b>
                                                            Custom Info Placeholders are currently assigned to{' '}
                                                            {clients?.fields?.value[0]?.name || 'Recipient 1'}.
                                                        </b>
                                                    </p>
                                                    <p>
                                                        By removing this Recipient from your document,{' '}
                                                        {clients?.fields?.value[1]?.name || 'the second recipient'} will
                                                        as a result inherit the assigned Custom Info Placeholders and
                                                        will be required to complete their responses as they will be the
                                                        new Recipient 1.
                                                    </p>
                                                </>
                                            }
                                            confirmText="Yes, remove Recipient"
                                            cancelText="No, do not remove"
                                            onConfirm={async () => {
                                                await deleteClient(index, callback);
                                                onClose();
                                            }}
                                            onCancel={onClose}
                                        />
                                    );
                                }
                            });
                        } else {
                            await deleteClient(index, callback);
                        }
                    }}
                    itemDisplayNameLowercase="recipient"
                    isReadOnly={isReadOnly}
                    isReorderEnabled={isReorderEnabled}
                    collapseLastClient={collapseLastClient}
                    setCollapseLastClient={setCollapseLastClient}
                    header={
                        isSequentialSigningEnabled &&
                        clientsLength > 1 && (
                            <SequentialSigningToggle
                                isDisabled={isReadOnly}
                                hasSignerPlaceholders={hasSignerPlaceholders}
                            />
                        )
                    }
                    renderCollapsedView={(item, index) => {
                        return (
                            <RecipientSummaryContent
                                client={item}
                                index={index}
                                hasError={!isEmpty(errors?.clients?.[index])}
                            />
                        );
                    }}
                    renderExpandedHeader={(item, index) => {
                        return <h4 className={styles.recipientHeader}>Recipient {index + 1} Details</h4>;
                    }}
                    renderExpandedView={(_, index) => {
                        return (
                            <div
                                className={cx(styles.fields, {
                                    [styles.withPhoneInput]: showPhoneInput
                                })}
                            >
                                <TextInputField
                                    name={`clients[${index}].name`}
                                    label="Recipient Name"
                                    isRequired
                                    placeholder="Enter full name"
                                    className={styles.nameField}
                                    validateOnTouch={false}
                                />
                                <TextInputField
                                    name={`clients[${index}].email`}
                                    label="Email"
                                    isRequired
                                    placeholder="Enter email address"
                                    className={styles.emailField}
                                    validateOnTouch={false}
                                />
                                {showPhoneInput && (
                                    <FormPhone
                                        name={`clients[${index}].phone`}
                                        label="Phone"
                                        required
                                        className={styles.phoneField}
                                        validateOnTouch={false}
                                        dataTest={`clients[${index}].phone`}
                                    />
                                )}
                                {isConnectIdEnabled && !isTemplate && <div className={styles.requireIdContainer}>
                                    <CheckboxCheck
                                        containerClassName={styles.requireIdCheckbox}
                                        name={`clients[${index}].requireProofOfIdentity`}
                                        label="Require Proof of Identity via ConnectID"
                                    />
                                    <Icon
                                        className={styles.helpIcon}
                                        icon={Icons.HELP}
                                        size={IconSize.SMALL}
                                        data-for={`connect-id-tooltip-${componentId}-${index}`}
                                        data-tip
                                    />
                                    <Tooltip
                                        effect="solid"
                                        place="bottom"
                                        id={`connect-id-tooltip-${componentId}-${index}`}
                                        clickable
                                        className={styles.connectIdTooltip}
                                    >
                                        <p>
                                            FLK is currently trialling ConnectID for a short period. Usage is FREE of
                                            charge until further notice. If enabled, please ensure you enter in the
                                            Recipient’s full legal name before sending.
                                        </p>
                                        <p>
                                            ConnectID is an Australian-owned digital identity solution which allows you
                                            to securely prove who you are. ConnectID does not see or store your personal
                                            data. Visit <a href="https://www.connectid.com.au">connectid.com.au</a> to
                                            find out more.
                                        </p>
                                    </Tooltip>
                                </div> }
                            </div>
                        );
                    }}
                    deletingItemIndex={deletingClientIndex ?? undefined}
                />
            )}
        </div>
    );
};
