import { INTEGRATION_LABEL, UploadStatus } from '@app/constants/constants';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
    uploadDocumentIntegration,
    updateDashboardIntegrationForTerminationNotice
} from '../../../actions/dashboard.js';
import { updateDocumentIntegrationForTerminationNotice } from '../../../actions/document.js';
import { DOCUMENT_TERMINATION_NOTICE, type ValidDocumentTypes } from '@app/config';

export type AgreementIntegrationType = {
    integration: {
        id: string;
        address: string;
        type: keyof typeof INTEGRATION_LABEL;
        active: boolean;
        lastSuccessfulUpload: string;
        uploadStatus: UploadStatus;
    };
};

type AgreementIdType = { docId: string };
type AgreementType = AgreementIdType & { documentType: 'lease' | ValidDocumentTypes };

const useIntegrationUploadDocuments = () => {
    const dispatch = useDispatch();

    const getUploadStatus = ({ integration }: AgreementIntegrationType) => {
        return integration?.uploadStatus ?? UploadStatus.DEFAULT;
    };

    const integrationUploadDocumentsMutation = useMutation({
        mutationFn: async ({ docId }: AgreementIdType) => {
            if (!docId) {
                throw new Error(
                    'Required parameters are missing or invalid. Please check the upload document request.'
                );
            }

            const response = await axios.post(`/api/agency/lease/${docId}/integration-upload`);
            return response.data;
        }
    });

    const integrationUploadTerminationOfNoticeDocumentsMutation = useMutation({
        mutationFn: async ({ docId }: AgreementIdType) => {
            if (!docId) {
                throw new Error(
                    'Required parameters are missing or invalid. Please check the upload document request.'
                );
            }

            const response = await axios.post(`/api/document/termination-notice/${docId}/integration-upload`);
            return response.data;
        }
    });

    const handleUploadClick = ({ docId, documentType }: AgreementType) => {
        if (!docId) {
            return;
        }

        if (documentType === DOCUMENT_TERMINATION_NOTICE) {
            dispatch(updateDocumentIntegrationForTerminationNotice(docId));
            dispatch(updateDashboardIntegrationForTerminationNotice(docId));
            integrationUploadTerminationOfNoticeDocumentsMutation.mutate({ docId });
        } else {
            dispatch(uploadDocumentIntegration(docId));
            integrationUploadDocumentsMutation.mutate({ docId });
        }
    };

    return { getUploadStatus, handleUploadClick };
};

export default useIntegrationUploadDocuments;
