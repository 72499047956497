import axios from 'axios';
import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import { cloneDeep, isEmpty, omit, set } from 'lodash';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Form, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
    addOrReplaceDocumentInList,
    closeCustomDocumentModal,
    refreshDocumentList,
    removeDocumentInList,
    setCurrentDocument,
    setDisabledSendForApproval,
    setPdfGeneratedState
} from '../../../../actions/document';
import ModalDialog from '../../../../common/components/ModalDialog';
import ClientAcknowledgement from '../../../../components/ClientAcknowledgement.tsx';
import { FormTextMultiline } from '../../../../components/form/FormText';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';
import useResendCounter from '../../../../hooks/useResendCounter';
import useToast from '../../../../hooks/useToast.ts';
import UploadADocSummary from './UploadADocSummary';
import UploadedDocumentMobile from './UploadedDocumentMobile.tsx';

import '../../../../sass/FlkAPdfForm.scss';
import '../../../../sass/noAddressDocumentModal.scss';

import { arrayMove } from '@dnd-kit/sortable';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { CloseSharp } from '@flk-mui-icons';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import appHistory from '../../../../AppHistory';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_COMPLETE,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../actions/dashboard';
import { getTemplates } from '../../../../actions/template';
import {
    AcknowledgeResponseIcon,
    EmailDeliveryV2Icon,
    IconSize,
    QrCodeIcon,
    SignResponseIcon,
    SmsDeliveryV2Icon
} from '../../../../common/components/Icon.tsx';
import Note, { NoteType } from '../../../../common/components/Note.tsx';
import { ToastTypes } from '../../../../common/components/Toast';
import TooltipIcon, { TooltipIconType } from '../../../../common/components/TooltipIcon.tsx';
import { NewDocumentHeader } from '../../../../components/dashboard/InfoAgreementComponents/NewDocumentHeaderLeft';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import { FormRadioGroupButton } from '../../../../components/form/FormRadioGroupButton';
import {
    CLOSE_MODAL_MESSAGE,
    CONTACT_TYPE_AUTO_SAVE,
    DOCUMENT_CUSTOM,
    DOCUMENT_DELIVERY_TYPE_EMAIL,
    DOCUMENT_DELIVERY_TYPE_QR_CODE,
    DOCUMENT_DELIVERY_TYPE_SMS,
    FLK_A_PDF_CREATION_DISPLAY,
    FLK_A_PDF_CREATION_MOBILE_DISPLAY,
    FLK_A_PDF_CREATION_TEMPLATE_DISPLAY,
    FLK_A_PDF_GLOBAL_TEMPLATE,
    FLK_A_PDF_TEMPLATE,
    FORM_SUBMIT_TYPE_SAVE,
    FORM_SUBMIT_TYPE_SEND,
    FormSubmitType,
    PDF_GENERATE_WAIT_TIME,
    isMobileWidth,
    viewUploadedDocumentFromDocument
} from '../../../../config';
import {
    ACKNOWLEDGE_CONFIRMATION_TYPE,
    BULK_SEND_APPROVAL_STATUS_DECLINED,
    BULK_SEND_APPROVAL_STATUS_PENDING,
    CLONE_TEMPLATE,
    EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE,
    EXECUTION_TYPE_EMBEDDED_SIGNATURE,
    PDF_GEN_COMPLETE,
    PDF_GEN_PENDING,
    PDF_GEN_PROCESSING,
    SIGN_CONFIRMATION_TYPE,
    UAD_MOBILE_WIDTH
} from '../../../../constants/constants';
import {
    COMPLETION_LIST_V2,
    ENFORCE_SIGNING_ORDER,
    LIST_NOW,
    MULTIPLE_RECIPIENTS_CLIENT_PLACEHOLDERS,
    QR_CODE_SHARING,
    SEQUENTIAL_SIGNING,
    TEMPLATE_SCREEN_V2,
    UPLOAD_A_DOC_RESPONSIVE
} from '../../../../constants/featureFlags';
import { useDocumentUpdate } from '../../../../hooks/useDocumentUpdate';
import { getAgencyPropertyMeIntegration, getAgencyPropertyTreeIntegration } from '../../../../selectors/agency';
import { getMobileViewEnabled } from '../../../../selectors/dashboard/index.js';
import {
    getCurrentDocument,
    getDocumentEditMode,
    getIsGlobalTemplate,
    getPdfGeneratedState
} from '../../../../selectors/document';
import { getIsCustomDocumentModalOpen } from '../../../../selectors/document/customDocument';
import { getIsListNowEnabled, getUserInfo } from '../../../../selectors/user';
import { CustomPlaceholderRespondentType } from '../../../../types/UploadADoc.ts';
import { DEADLINE_OPTIONS } from '../../../../utils/formUtils';
import {
    isDocumentAwaitingCompletion,
    isDocumentComplete,
    isDocumentReadOnlyMode,
    isDocumentStatusDraft,
    isDocumentStatusSentForSigning,
    isDocumentTemplateMode,
    isDocumentTemplateReadOnlyMode
} from '../../../../utils/generalUtils';
import { isBusinessUserAccount } from '../../../../utils/userUtils';
import PdfViewer from '../../../PdfViewer/PdfViewer';
import { SidebarAccordionItems } from '../../../PdfViewer/components/Sidebar.tsx';
import { useUploadedDocumentStateItems } from '../../../PdfViewer/components/useUploadedDocumentState.ts';
import {
    deleteAnnotationsForCustomPlaceholder,
    doUploadedDocumentsHaveSignatures,
    getCustomPlaceholderCountsAfterDeletingDocument,
    getDocumentsWithUpdatedAnnotations,
    reorderClientAnnotations
} from '../../../PdfViewer/utils.ts';
import { agreementsSentAlert } from '../../../shared/DocumentConfirmAlertHelper';
import DocumentTitleHeader from '../../DocumentTitleHeader';
import { FlkAPdfHelp } from '../../help/FlkAPdfHelp';
import DocumentSignatures from '../DocumentSignatures';
import CustomPlaceholderFormSection from './CustomPlaceholderFormSection';
import FlkAPdfCreate from './FlkAPdfCreate';
import FlkAPdfFooter, {
    ViewDocumentButtonGroup,
    ViewDocumentWithAcknowledgementButtonGroup,
    getViewPDFToolTipText,
    viewPdf
} from './FlkAPdfFooter';
import FlkAPdfSummary from './FlkAPdfSummary';
import LinkedPartnersSummary from './LinkedPartnersSummary.tsx';
import ListNow from './ListNow.tsx';
import QrCodeSharing from './QrCodeSharing';
import DeactivateQrCodeListeners from './DeactivateQrCodeListeners.tsx';
import { RecipientSectionV2 } from './RecipientSectionV2';
import UnevenSignaturesConfirmModal from './UnevenSignaturesConfirmModal';
import UploadADocUpload from './UploadADocUpload';
import { getIntegrationPropertyDetails } from './utils.ts';
import Listen from '../buildADoc/components/Listen.tsx';
import EditRecipientsModal from './EditRecipientsModal.tsx';

function FlkAPdfForm() {
    const SIGN = 'sign';
    const ACKNOWLEDGE = 'acknowledge';

    const CONFIRMATION_TEXT = 'I confirm I have read and reviewed the document(s)';

    const isForceSigningOrderEnabled = useFeatureFlag(ENFORCE_SIGNING_ORDER);
    const isSequentialSigningEnabled = useFeatureFlag(SEQUENTIAL_SIGNING);
    const isMultipleRecipientsClientPlaceholdersEnabled = useFeatureFlag(MULTIPLE_RECIPIENTS_CLIENT_PLACEHOLDERS);

    const dispatch = useDispatch();
    const { addNewToast } = useToast();
    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);
    const isUploadADocResponsiveEnabled = useFeatureFlag(UPLOAD_A_DOC_RESPONSIVE);
    const isCompletionListV2Enabled = useFeatureFlag(COMPLETION_LIST_V2);
    const isListNowFeatureEnabled = useFeatureFlag(LIST_NOW);
    const isListNowEnabled = useSelector(getIsListNowEnabled);
    const isOpenCustomDocumentForm = useSelector(getIsCustomDocumentModalOpen);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const documentEditMode = useSelector(getDocumentEditMode);
    const loggedInUser = useSelector(getUserInfo);
    const currentDocument = useSelector(getCurrentDocument);
    const userInfo = useSelector(getUserInfo);
    const history = useHistory();
    const isIntegrationActive = propertyMeIntegration || propertyTreeIntegration;
    const pdfGeneratedState = useSelector(getPdfGeneratedState);
    const isGlobalTemplate = useSelector(getIsGlobalTemplate);
    const mobileViewEnabled = useSelector(getMobileViewEnabled);

    const isQrCodeSharingActive = useFeatureFlag(QR_CODE_SHARING);

    const isMobileView = isUploadADocResponsiveEnabled && mobileViewEnabled && isMobileWidth(UAD_MOBILE_WIDTH);
    const templateType = isGlobalTemplate ? FLK_A_PDF_GLOBAL_TEMPLATE : FLK_A_PDF_TEMPLATE;

    const [hasUpdatedCustomPlaceholdersSinceOpeningPdf, setHasUpdatedCustomPlaceholdersSinceOpeningPdf] =
        useState(false);

    const formValuesRef = useRef(null);

    /**
     * These variables below are for the saving before closing functionality
     */
    const [isDirty, setIsDirty] = useState(false);
    const handleFormDirtyChange = form => {
        if (form.getState().dirty && !isDirty) {
            setIsDirty(true);
        }
    };
    const [creatingDocument, setCreatingDocument] = useState(false);
    const [documentCreated, setDocumentCreated] = useState(false);
    const [isScrollToAnchor, setIsScrollToAnchor] = useState(false);

    const [isSavingForPdfViewer, setIsSavingForPdfViewer] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitFail, setIsSubmitFail] = useState(false);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

    const [isSending, setIsSending] = useState(false);
    const [isSendSuccessful, setIsSendSuccessful] = useState(false);
    const [isSendFail, setIsSendFail] = useState(false);

    const [uploadedDocumentsList, setUploadedDocumentsList] = useState([]);
    const [backendError, setBackendError] = useState(null);

    const [backendErrors, setBackendErrors] = useState(false);

    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
    const [customDocumentId, setCustomDocumentId] = useState(false);
    const [annotationsCount, setAnnotationsCount] = useState(null);

    const [formData, setFormData] = useState({});
    const [hasAnnotations, setHasAnnotations] = useState(false);
    const isReadOnly = isDocumentReadOnlyMode(documentEditMode);

    const [isOpenConfirmAlert, setIsOpenConfirmAlert] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [currentConfirmationType, setCurrentConfirmationType] = useState(undefined);
    const [currentExecutionType, setCurrentExecutionType] = useState(undefined);

    const [shouldShowPdfViewer, setShouldShowPdfViewer] = useState(false);

    const [isOpenEditRecipientsModal, setIsOpenEditRecipientsModal] = useState(false);

    const isCustomPlaceholdersDirtyRef = useRef(false);

    const [isOpenResendModal, setIsOpenResendModal] = useState(false);

    const {
        uploadedDocumentStateItems,
        selectedUploadedDocId,
        setSelectedUploadedDocId,
        addUploadedDocumentStateItem,
        removeUploadedDocumentStateItem,
        resetVisitedState,
        handleUpdateAnnotationCount,
        defaultOpenSidebarItems,
        setDefaultOpenSidebarItems,
        setIsWebViewerInitialised,
        areAllDocumentsInitialised
    } = useUploadedDocumentStateItems();

    const doubleSendGuard = useDoubleSendGuard();
    const { resendWaitSeconds, startResendCounter } = useResendCounter();

    const CUSTOM_DOCUMENT_URL = `api/document/custom-document/${customDocumentId}`;

    useDocumentUpdate(userInfo.agency, item => {
        if (
            currentDocument &&
            item.descriptor.data.id === currentDocument.id &&
            item.descriptor.data.pdfGeneratedState
        ) {
            dispatch(setPdfGeneratedState(item.descriptor.data.pdfGeneratedState));
        }
    });

    const clearFormData = useCallback(() => {
        if (currentDocument) {
            if (currentDocument.requiresApproval) {
                if (
                    [BULK_SEND_APPROVAL_STATUS_PENDING, BULK_SEND_APPROVAL_STATUS_DECLINED].includes(
                        currentDocument.bulkSendApprovalStatus
                    )
                ) {
                    dispatch(setDisabledSendForApproval(true));
                }
            }
            if (currentDocument.hasAnnotations) {
                setHasAnnotations(true);
            } else {
                setHasAnnotations(false);
            }
            setCustomDocumentId(currentDocument.id);
            setUploadedDocumentsList(currentDocument.uploadedDocuments);
            if (currentDocument.annotationsCount) {
                setAnnotationsCount(JSON.parse(currentDocument.annotationsCount));
            }
            setDocumentCreated(!currentDocument.isCreatedFromTemplate);

            //set the execution type based on the documents uploaded from the create screen.
            if (!currentDocument.confirmationType) {
                if (currentDocument?.uploadedDocuments?.length > 1) {
                    setCurrentExecutionType(EXECUTION_TYPE_EMBEDDED_SIGNATURE);
                    setCurrentConfirmationType(SIGN_CONFIRMATION_TYPE);
                }
            }

            if (currentDocument.isCreatedFromTemplate && currentDocument.isCloneFromTemplate) {
                setSelectedTemplate({
                    value: currentDocument.id,
                    label: currentDocument.templateName ? currentDocument.templateName : currentDocument.documentTitle,
                    documentTitle: currentDocument.documentTitle,
                    type: CLONE_TEMPLATE
                });
            }
        } else {
            dispatch(setDisabledSendForApproval(false));
            setCustomDocumentId(null);
            setDocumentCreated(false);
            setUploadedDocumentsList([]);
        }
        let templateName;
        if (currentDocument && isDocumentTemplateMode(documentEditMode) && currentDocument.isCreatedFromTemplate) {
            templateName = currentDocument.templateName ? currentDocument.templateName : currentDocument.documentTitle;
        }

        let clientData;
        // if client was already saved in client document use that data
        if (currentDocument && currentDocument.client) {
            if (isEmpty(currentDocument.client.clients)) {
                clientData = [
                    {
                        name: '',
                        email: '',
                        phone: '',
                        source: CONTACT_TYPE_AUTO_SAVE
                    }
                ];
            } else {
                clientData = currentDocument.client.clients;
            }
        } else {
            // if there is an integration active we want to make that search more prominent so lets not put an empty client in the view
            clientData = [
                {
                    name: '',
                    email: '',
                    phone: '',
                    source: CONTACT_TYPE_AUTO_SAVE
                }
            ];
        }

        setFormData(
            currentDocument
                ? {
                      documentTitle: currentDocument.isCreatedFromTemplate ? currentDocument.documentTitle : undefined,
                      descriptionText: currentDocument.descriptionText || `Please review the following document(s).`,
                      includeDescriptionTextInEmail: currentDocument.includeDescriptionTextInEmail,
                      enforceSigningOrder: currentDocument.enforceSigningOrder,
                      clients: clientData,
                      deliveryType: currentDocument.deliveryType,
                      executionType: currentDocument.executionType
                          ? currentDocument.executionType
                          : currentExecutionType,
                      confirmationType: currentConfirmationType
                          ? currentConfirmationType
                          : currentDocument.confirmationType,
                      acknowledgementText: currentDocument.acknowledgementText || CONFIRMATION_TEXT,
                      deadline: `${currentDocument.deadline ? currentDocument.deadline : DEADLINE_OPTIONS[2].value}`, //has to be a string
                      signElectronically: true,
                      annotations: currentDocument.annotations,
                      annotationsCount: currentDocument.annotationsCount,
                      optimisedPdfFile: currentDocument.optimisedPdfFile,
                      templateName,
                      isBulkCreation: currentDocument.isBulkCreation,
                      contactsList: currentDocument.contactsList,
                      customPlaceholders: currentDocument.customPlaceholders,
                      address: currentDocument.address,
                      integrationPropertyId: currentDocument.integrationPropertyId,
                      enforceSequentialSigning: currentDocument.enforceSequentialSigning,
                      listNow: currentDocument.listNow,
                      enableQrCode: currentDocument.enableQrCode
                  }
                : {
                      descriptionText: `Please review the following document(s).`,
                      acknowledgementText: CONFIRMATION_TEXT,
                      deadline: DEADLINE_OPTIONS[2].value,
                      clients: clientData,
                      deliveryType: undefined,
                      confirmationType: undefined,
                      signElectronically: true
                  }
        );
    }, [currentDocument, loggedInUser, documentEditMode, isIntegrationActive]);

    useEffect(() => {
        if (isScrollToAnchor) {
            scrollToAnchor();
        }
    }, [isScrollToAnchor]);

    useEffect(() => {
        if (currentDocument && isOpenCustomDocumentForm) {
            setIsScrollToAnchor(false);
            if (window.location.href.indexOf('#annotation-viewer-anchor') !== -1) {
                setIsScrollToAnchor(true);
            }
            window.history.pushState({}, '', `/dashboard/document/${currentDocument.id}`);
        }
    }, [isOpenCustomDocumentForm, currentDocument]);

    useEffect(() => {
        clearFormData();
    }, [clearFormData, isOpenCustomDocumentForm, loggedInUser]);

    const handlePdfViewerSaveSuccess = React.useCallback(
        updatedDocument => {
            resetVisitedState();
            dispatch(setCurrentDocument(updatedDocument));
            if (isDocumentTemplateMode(documentEditMode)) {
                dispatch(getTemplates(templateType));
            } else {
                dispatch(addOrReplaceDocumentInList(updatedDocument));
            }
        },
        [resetVisitedState, documentEditMode, dispatch]
    );

    const closePdfViewer = React.useCallback(() => {
        if (hasUpdatedCustomPlaceholdersSinceOpeningPdf) {
            setHasUpdatedCustomPlaceholdersSinceOpeningPdf(false);
        }
        setShouldShowPdfViewer(false);
        setSelectedUploadedDocId(null);
    }, [setShouldShowPdfViewer, setSelectedUploadedDocId, hasUpdatedCustomPlaceholdersSinceOpeningPdf]);

    const agencyDetailsForPdfViewer = React.useMemo(
        () => ({
            name: loggedInUser?.agency?.details.agencyName,
            imageUrl: loggedInUser?.agency?.logo
        }),
        [loggedInUser?.agency]
    );

    const closeModal = () => {
        setIsDirty(false);
        if (currentDocument) {
            if (isDocumentTemplateMode(documentEditMode)) {
                if (isTemplateScreenV2Active) {
                    window.history.pushState({}, '', `/templates`);
                } else {
                    window.history.pushState({}, '', `/user/upload-a-doc-templates`);
                }
            } else {
                window.history.pushState(
                    {},
                    '',
                    `/dashboard/documents/${currentDocument.docType}/${currentDocument.status}`
                );
            }
        } else {
            appHistory.push('/dashboard/documents/document_custom/draft');
        }
        dispatch(closeCustomDocumentModal());
    };

    const handleCloseModal = () => {
        if (isDirty && [LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING].includes(currentDocument.status)) {
            confirmAlert({
                title: '',
                message: CLOSE_MODAL_MESSAGE,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            const values = formValuesRef.current;
                            const bypassFormValidation =
                                values.deliveryType !== DOCUMENT_DELIVERY_TYPE_QR_CODE || !values.enableQrCode;
                            await handleSaveForm(
                                formValuesRef.current,
                                uploadedDocumentsList,
                                () => closeModal(),
                                bypassFormValidation
                            );
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            closeModal();
                        }
                    },
                    {
                        className: 'close close-modal',
                        label: <CloseSharp>Close</CloseSharp>,
                        onClick: () => {}
                    }
                ]
            });
        } else {
            closeModal();
        }
    };

    function getFormValuesWithExecutionType(values, files) {
        const filesCount = files.length;
        let executionType;
        let confirmationType;
        if (values.confirmationType) {
            if (values.confirmationType === SIGN_CONFIRMATION_TYPE) {
                executionType = EXECUTION_TYPE_EMBEDDED_SIGNATURE;
                confirmationType = SIGN_CONFIRMATION_TYPE;
            } else {
                executionType = EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE;
                confirmationType = ACKNOWLEDGE_CONFIRMATION_TYPE;
            }
        } else {
            const existingFilesCount = currentDocument?.uploadedDocuments?.length ?? 0;
            if (existingFilesCount + filesCount > 1) {
                executionType = EXECUTION_TYPE_EMBEDDED_SIGNATURE;
                confirmationType = SIGN_CONFIRMATION_TYPE;
            } else {
                executionType = EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE;
                confirmationType = ACKNOWLEDGE_CONFIRMATION_TYPE;
            }
        }

        return {
            ...values,
            executionType,
            confirmationType
        };
    }

    function checkClientDetails(values) {
        const clients = values.clients;
        if (clients && clients.length > 0) {
            return clients.every(client => {
                return !isEmpty(client.email);
            });
        }
        return false;
    }

    const addAnnotationsForFile = function (file, files, values) {
        if (isDocumentTemplateMode(documentEditMode) || checkClientDetails(values)) {
            const data = getFormValuesWithExecutionType(values, files);
            setFormData(data);
            setIsDirty(true);
            if (files?.length > 0) {
                data.uploadedDocuments = files;
                return saveDocumentAndRedirectToAnnotationViewer(data, true, file);
            }
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body delete-user-modal">
                            <p>Looks like you have forgotten to add some recipient details.</p>
                            <br />
                            <p>Please fill in recipient details above.</p>
                            <div className="react-confirm-alert-button-group">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    );
                }
            });
        }
    };

    /**
     * Save deletion to the server
     * @param values
     * @param updatedFilesList
     * @returns {Promise<void>}
     */
    const deleteDocumentAndSave = async (form, updatedFilesList, deletedDocumentId) => {
        getCustomPlaceholderCountsAfterDeletingDocument(
            form,
            uploadedDocumentStateItems.find(item => item.uploadedDocumentId === deletedDocumentId)
        );
        const data = cloneDeep(form.getState().values);
        if (shouldDeactivateQrCode()) {
            deactivateQrCodeAndNotify();
            data.enableQrCode = false;
        }

        data.uploadedDocuments = updatedFilesList;
        if (updatedFilesList && updatedFilesList.length === 0) {
            setHasAnnotations(false);
            setAnnotationsCount(null);
        }
        setUploadedDocumentsList(updatedFilesList);
        removeUploadedDocumentStateItem(deletedDocumentId);

        await handleSaveForm(data, updatedFilesList);
    };

    const addDocumentsToListAndSave = async (files, values) => {
        // set the executionType type if one is not selected
        const data = getFormValuesWithExecutionType(values, files);
        if (shouldDeactivateQrCode()) {
            deactivateQrCodeAndNotify();
            data.enableQrCode = false;
        }
        const newDocumentsList = [...uploadedDocumentsList, ...uploadObjectsFromFiles(files)];

        data.uploadedDocuments = newDocumentsList;
        // Optimistically display new uploaded document list. In the event of failure to save form the document will appear
        // without a title link - the user will still be able to add placeholders from PDFTron.
        setUploadedDocumentsList(newDocumentsList);
        await handleSaveForm(data, newDocumentsList);
    };

    const reorderDocumentList = async (activeId, overId) => {
        if (shouldDeactivateQrCode()) {
            deactivateQrCodeAndNotify();
        }
        setUploadedDocumentsList(documents => {
            const activeIndex = documents.findIndex(uploadedDocument => uploadedDocument.id === activeId);
            const overIndex = documents.findIndex(uploadedDocument => uploadedDocument.id === overId);
            return arrayMove(documents, activeIndex, overIndex);
        });

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    /**
     * Omitting the uploadedDocumentId param will clear all annotations for all
     * uploadedDocuments
     * @param values
     * @param uploadedDocumentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    async function handleClearSignaturePlaceholders(values, uploadedDocumentId) {
        setHasAnnotations(false);
        setAnnotationsCount(null);
        setIsSubmitting(true);
        const data = {
            ...values
        };

        if (shouldDeactivateQrCode()) {
            data.enableQrCode = false;
            deactivateQrCodeAndNotify();
        }
        let url = `${CUSTOM_DOCUMENT_URL}/remove-all-annotations`;
        if (uploadedDocumentId) {
            url = `${CUSTOM_DOCUMENT_URL}/remove-annotations/${uploadedDocumentId}`;
        }

        setFormData(data);
        dispatch(setPdfGeneratedState(PDF_GEN_PENDING));
        return axios.post(url).then(response => {
            setIsSubmitting(false);
            if (response.data.customDocument) {
                dispatch(setCurrentDocument(response.data.customDocument));
            }
            setTimeout(() => {
                dispatch(setPdfGeneratedState(PDF_GEN_COMPLETE));
            }, PDF_GENERATE_WAIT_TIME);
            return response;
        });
    }

    //Iterate files and create uploadedDocumentObjects
    function uploadObjectsFromFiles(files) {
        const uploadedObjects = [];
        files.forEach(file => {
            uploadedObjects.push({ document: file });
        });
        return uploadedObjects;
    }

    function openPdfViewer(uploadedDoc) {
        setShouldShowPdfViewer(true);
        setSelectedUploadedDocId(uploadedDoc.id);
    }

    const saveDocumentAndRedirectToAnnotationViewer = async (values, bypassValidation = false, file = null) => {
        const data = cloneDeep(values);
        if (shouldDeactivateQrCode()) {
            deactivateQrCodeAndNotify();
            data.enableQrCode = false;
        }
        let url = `${CUSTOM_DOCUMENT_URL}`;
        if (bypassValidation) {
            url = url.concat(`?bypassFormValidation=1`);
        }
        if (data.uploadedDocuments && data.uploadedDocuments.length > 1) {
            data.executionType = EXECUTION_TYPE_EMBEDDED_SIGNATURE;
        }
        // Do not send phone numbers if delivery type is email
        if (!bypassValidation && data.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL) {
            data.clients?.forEach(client => {
                client.phone = undefined;
            });
        }

        if (isCustomPlaceholdersDirtyRef.current) {
            data.uploadedDocuments = await getDocumentsWithUpdatedAnnotations(
                data.uploadedDocuments,
                uploadedDocumentStateItems
            );
        }

        // If we have some saved clients and they have _ids, then we add a new client and reorder the clients.
        // Then mongo will create duplicate ids if we manually keep old ids
        data.clients.forEach(client => {
            client._id = undefined;
        });

        data.pdfGeneratedState = PDF_GEN_PROCESSING;
        dispatch(setPdfGeneratedState(PDF_GEN_PROCESSING));
        setIsSavingForPdfViewer(true);
        setBackendErrors(null);
        setIsSubmitFail(false);
        setIsSubmitSuccessful(false);
        setIsSendFail(false);
        return axios
            .post(url, data)
            .then(async response => {
                dispatch(setCurrentDocument(response.data.customDocument));
                setIsSubmitting(false);
                setIsSubmitSuccessful(true);
                if (isDocumentTemplateMode(documentEditMode)) {
                    dispatch(getTemplates(templateType));
                } else {
                    dispatch(addOrReplaceDocumentInList(response.data.customDocument));
                }
                setIsSavingForPdfViewer(false);
                if (file) {
                    openPdfViewer(file);
                } else if (response.data.customDocument.confirmationType === ACKNOWLEDGE) {
                    openPdfViewer(response.data.customDocument.uploadedDocuments[0]);
                }
            })
            .catch(error => {
                setIsSavingForPdfViewer(false);
                setIsSubmitting(false);
                setIsSubmitSuccessful(false);
                setIsSubmitFail(true);
                setIsOpenConfirmAlert(false);
                setBackendErrors(getErrorFromResponse(error));
                //remove signature type as there was an error in validating, so we want them to select again
                if (!hasAnnotations) {
                    const newData = {
                        ...data,
                        executionType: undefined
                    };
                    setFormData(newData);
                }

                return false;
            });
    };

    function scrollToFirstError() {
        const firstErrorElement = document.querySelector('[data-has-error="true"]');

        if (firstErrorElement) {
            setTimeout(() => {
                firstErrorElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }, 1000);
        }
    }

    const scrollToAnchor = () => {
        const element = document.getElementById('annotation-viewer-anchor');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    };

    function getErrorFromResponse(error) {
        const response = error.response;
        if (response && response.data && response.data.errors) {
            let err = response.data.errors;
            if (err.client) {
                err.clients = err.client.clients;
            }
            return err;
        }
        return false;
    }

    const sendForSigning = async values =>
        doubleSendGuard(async () => {
            const data = cloneDeep(values);
            // We always want to get updated annotations when sending for signing so we can remove any hidden annotations.
            data.uploadedDocuments = await getDocumentsWithUpdatedAnnotations(
                data.uploadedDocuments,
                uploadedDocumentStateItems,
                { removeHidden: true }
            );

            // Do not send phone numbers if delivery type is email
            if (data.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL) {
                data.clients?.forEach(client => {
                    client.phone = undefined;
                });
            }
            // If we have some saved clients and they have _ids, then we add a new client and reorder the clients.
            // Then mongo will create duplicate ids if we manually keep old ids
            data.clients.forEach(client => {
                client._id = undefined;
            });
            setIsSendSuccessful(false);
            setIsSendFail(false);
            setIsSubmitFail(false);
            setIsSending(true);
            return axios
                .post(`api/document/custom-document/${customDocumentId}/send-for-signing`, data)
                .then(response => {
                    if (response.data.success) {
                        startResendCounter();
                        setIsDirty(false);
                        setIsSending(false);
                        setIsSendSuccessful(true);
                        setIsSubmitSuccessful(false);
                        if (response.data.customDocument) {
                            dispatch(removeDocumentInList(response.data.customDocument, LEASE_STATUS_DRAFT));
                        } else if (response.data.documentList && currentDocument) {
                            // If bulk creation remove old document
                            dispatch(removeDocumentInList(currentDocument));
                        }
                        addNewToast('Document sent successfully', ToastTypes.SUCCESS, true);
                        if (!isMobileView) {
                            appHistory.push(`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_SENT_SIGNING}`);
                        }
                        closeModal();
                        return true;
                    } else {
                        setIsSending(false);
                        setIsSendSuccessful(false);
                        setIsSendFail(true);
                        addNewToast('Document send failed', ToastTypes.ERROR, true);
                    }
                })
                .catch(error => {
                    clearDoubleSendGuard();
                    setIsSending(false);
                    setIsSendSuccessful(false);
                    setIsSubmitSuccessful(false);
                    setIsSendFail(true);
                    setBackendErrors(getErrorFromResponse(error));
                    addNewToast('Document send failed', ToastTypes.ERROR, true);
                    setTimeout(() => {
                        scrollToFirstError();
                    }, 1000);
                    return false;
                });
        });

    const validateDocumentAnnotations = data => {
        // Interim fix for FLK-6375
        const doAllDocumentsHaveSignatures = doUploadedDocumentsHaveSignatures(uploadedDocumentStateItems);
        const doAllClientsHaveAnnotations =
            data.deliveryType === DOCUMENT_DELIVERY_TYPE_QR_CODE
                ? clientHasAnnotations(0)
                : data.clients.every((_, index) => clientHasAnnotations(index));

        if (
            data.confirmationType === SIGN &&
            (!hasAnnotations || !currentDocument.allDocsHaveClientSignatureAnnotations || !doAllDocumentsHaveSignatures)
        ) {
            // This checks that we have only blocked sending due to our interim fix
            if (
                !doAllDocumentsHaveSignatures &&
                hasAnnotations &&
                currentDocument.allDocsHaveClientSignatureAnnotations
            ) {
                window.rollbar.error(
                    'Document blocked for sending because there were no annotations on the PDF and the signature count was incorrect.',
                    {
                        documentId: currentDocument.id,
                        environment: ENVIRONMENT
                    }
                );
            }

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body delete-user-modal">
                            <p>Looks like you have forgotten to add signature placeholders on one of your documents.</p>
                            <br />
                            <p>At least one placeholder is needed per document.</p>
                            <div className="react-confirm-alert-button-group">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    );
                }
            });
            return false;
        } else if (data.confirmationType === SIGN && !doAllClientsHaveAnnotations) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body delete-user-modal">
                            <p>Not all recipients have a signature placeholder.</p>
                            <br />
                            <p>
                                Please ensure each recipient has at least one signature placed on a document before
                                sending.
                            </p>
                            <div className="react-confirm-alert-button-group">
                                <button
                                    type="button"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    );
                }
            });
            return false;
        }

        return true;
    };

    async function handleSendForSigning(data) {
        const areAnnotationsValid = validateDocumentAnnotations(data);
        data.uploadedDocuments = uploadedDocumentsList;
        if (areAnnotationsValid) {
            // if it has been sent in the past, it won't change anything about the quota
            if (isBusinessUserAccount(loggedInUser.accountType) && !currentDocument.firstSentForSigning) {
                return agreementsSentAlert(data, loggedInUser, sendForSigning, dispatch, history);
            } else {
                return sendForSigning(data);
            }
        }
    }

    const allClientsHaveSameAmountOfSignatures = values => {
        if (values.isBulkCreation) {
            return true;
        } else {
            let haveSameAmountOfSignatures = true;
            if (annotationsCount) {
                let noOfSignatures = annotationsCount[0];
                values.clients.forEach((client, index) => {
                    if (noOfSignatures !== annotationsCount[index]) {
                        haveSameAmountOfSignatures = false;
                    }
                });
            }
            return haveSameAmountOfSignatures;
        }
    };

    const handleSubmit = (values, form) => {
        let data = cloneDeep(values);

        // add documents to values
        data.uploadedDocuments = uploadedDocumentsList;

        setDocumentUploadProgress(0);

        if (!hasAnnotations) {
            data.annotations = undefined;
        }

        if (values.submitType === FORM_SUBMIT_TYPE_SEND) {
            if (!allClientsHaveSameAmountOfSignatures(values)) {
                setIsOpenConfirmAlert(true);
            } else {
                if (values.enforceSequentialSigning) {
                    data = {
                        ...data,
                        clients: data.clients.map((item, index) => ({
                            ...item,
                            signingOrder: index + 1
                        }))
                    };
                }

                return handleSendForSigning(data).finally(() => {
                    // reset to type save in case the user submits with enter
                    form.change('submitType', FORM_SUBMIT_TYPE_SAVE);
                    setIsDirty(false);
                });
            }
        } else {
            const bypassFormValidation = values.deliveryType !== DOCUMENT_DELIVERY_TYPE_QR_CODE || !values.enableQrCode;
            return handleSaveForm(data, uploadedDocumentsList, null, bypassFormValidation);
        }
    };

    async function handleSaveForm(values, uploadedDocuments, onSuccess, bypassFormValidation = true) {
        setIsSubmitFail(false);
        setIsSubmitSuccessful(false);
        setIsSendFail(false);
        setBackendError(null);
        setIsSubmitting(true);

        const data = cloneDeep(values);
        data.uploadedDocuments = uploadedDocuments;
        data.uploadedDocuments = await getDocumentsWithUpdatedAnnotations(
            data.uploadedDocuments,
            uploadedDocumentStateItems
        );

        let areAnnotationsValid;
        if (!bypassFormValidation) {
            areAnnotationsValid = validateDocumentAnnotations(data);

            if (!areAnnotationsValid) {
                setIsSubmitting(false);
                setIsSubmitFail(true);
                setIsSubmitSuccessful(false);
                return;
            }
        }

        if (bypassFormValidation || areAnnotationsValid) {
            //Add the annotations
            // If we have some saved clients and they have _ids, then we add a new client and reorder the clients.
            // Then mongo will create duplicate ids if we manually keep old ids
            data.clients?.forEach(client => {
                client._id = undefined;
            });

            return axios
                .post(`${CUSTOM_DOCUMENT_URL}?bypassFormValidation=${bypassFormValidation ? 1 : 0}`, data)
                .then(async response => {
                    setIsSubmitting(false);
                    setIsSubmitSuccessful(true);
                    setIsDirty(false);
                    setUploadedDocumentsList(response.data.customDocument.uploadedDocuments);
                    if (isDocumentTemplateMode(documentEditMode)) {
                        dispatch(getTemplates(templateType));
                    } else {
                        // Note that this is referring to the dashboard documents list, NOT the list of uploaded documents
                        dispatch(refreshDocumentList());
                    }
                    dispatch(setCurrentDocument(response.data.customDocument));
                    onSuccess?.();
                })
                .catch(error => {
                    setIsSubmitting(false);
                    setIsSubmitSuccessful(false);
                    setIsSubmitFail(true);
                    setBackendErrors(getErrorFromResponse(error));
                    return getErrorFromResponse(error);
                });
        }
    }

    const linkIntegration = async propertyDetails => {
        if (propertyDetails) {
            return await handleSaveForm(
                {
                    ...formValuesRef.current,
                    address: propertyDetails.address,
                    integrationPropertyId: propertyDetails.integrationPropertyId,
                    integration: propertyDetails.integration,
                    integrationType: propertyDetails.integrationType,
                    vaultReLifeId: propertyDetails.vaultReLifeId,
                    vaultReLifeType: propertyDetails.vaultReLifeType
                },
                uploadedDocumentsList
            );
        }
    };

    const unLinkIntegration = async () => {
        return await handleSaveForm(
            {
                ...formValuesRef.current,
                address: null,
                integrationPropertyId: null,
                integration: null,
                integrationType: null,
                vaultReLifeId: null,
                vaultReLifeType: null
            },
            uploadedDocumentsList
        );
    };

    /**
     * Custom header on a modal
     * @returns {JSX.Element}
     */
    function customHeaderContent() {
        if (currentDocument && documentCreated) {
            let docType = DOCUMENT_CUSTOM;
            if (isDocumentTemplateMode(documentEditMode)) {
                docType = FLK_A_PDF_TEMPLATE;
            }
            return (
                <DocumentTitleHeader
                    disabled={isDocumentReadOnlyMode(documentEditMode)}
                    doc={currentDocument}
                    closeModal={handleCloseModal}
                    showHelpIcon={true}
                    helpComponent={<FlkAPdfHelp />}
                    isTemplate={isDocumentTemplateMode(documentEditMode)}
                    isReadOnlyTemplate={isDocumentTemplateReadOnlyMode(documentEditMode)}
                    docType={docType}
                    showLockedIcon={true}
                    hideCloseButton={isSubmitting || isSending}
                    integrationAddress={currentDocument.integrationPropertyId ? currentDocument.address : ''}
                    linkIntegrationProperty={linkIntegration}
                    unLinkIntegrationProperty={unLinkIntegration}
                    saveDoc={async isEditPdf => {
                        const values = cloneDeep(formValuesRef.current);
                        const data = getFormValuesWithExecutionType(values, uploadedDocumentsList);
                        data.uploadedDocuments = uploadedDocumentsList;
                        if (isEditPdf) {
                            return saveDocumentAndRedirectToAnnotationViewer(data, false, data.uploadedDocuments[0]);
                        } else {
                            data.uploadedDocuments = await getDocumentsWithUpdatedAnnotations(
                                data.uploadedDocuments,
                                annotationsCount
                            );
                            return axios.post(`${CUSTOM_DOCUMENT_URL}?bypassFormValidation=1`, data);
                        }
                    }}
                    hideActions={isMobileView}
                    signersLabel="Recipients"
                    editSigners={() => setIsOpenEditRecipientsModal(true)}
                />
            );
        } else {
            return (
                <NewDocumentHeader
                    headerTitle={
                        isMobileView
                            ? FLK_A_PDF_CREATION_MOBILE_DISPLAY
                            : isDocumentTemplateMode(documentEditMode)
                              ? FLK_A_PDF_CREATION_TEMPLATE_DISPLAY
                              : FLK_A_PDF_CREATION_DISPLAY
                    }
                />
            );
        }
    }

    function clientHasAnnotations(clientIndex) {
        let hasAnnotations = false;
        if (currentDocument && currentDocument.uploadedDocuments && currentDocument.uploadedDocuments.length > 0) {
            hasAnnotations = currentDocument.uploadedDocuments.some(uploadedDoc => {
                if (uploadedDoc.annotationsCount) {
                    const annotationsCount = JSON.parse(uploadedDoc.annotationsCount);
                    const clientCount = annotationsCount[clientIndex];
                    return clientCount != null && clientCount !== 0;
                }
            });
        }
        return hasAnnotations;
    }

    const renderUploadedDocuments = () => {
        return (
            <>
                {currentDocument.uploadedDocuments.map((uploadedDocument, index) => {
                    return (
                        <div
                            key={index}
                            className="button-footer-docs"
                            data-test={`${uploadedDocument.id}-footer-buttons`}
                        >
                            {currentDocument &&
                            currentDocument.status === LEASE_STATUS_COMPLETE &&
                            currentDocument.executionType === EXECUTION_TYPE_EMBEDDED_SIGNATURE ? (
                                <ViewDocumentWithAcknowledgementButtonGroup
                                    uploadedDocument={uploadedDocument}
                                    showToolTip={false}
                                    currentDocument={currentDocument}
                                    pdfGenerated={pdfGeneratedState || PDF_GEN_COMPLETE}
                                />
                            ) : (
                                <ViewDocumentButtonGroup
                                    documentName={uploadedDocument.document.documentName}
                                    tooltipText={getViewPDFToolTipText(true, pdfGeneratedState)}
                                    disabled={
                                        pdfGeneratedState === PDF_GEN_PROCESSING ||
                                        pdfGeneratedState === PDF_GEN_PENDING
                                    }
                                    onViewPdf={() => viewPdf(currentDocument.id, uploadedDocument.id)}
                                />
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    const formRef = useRef(null);

    const shouldDeactivateQrCode = () => {
        return !!formValuesRef.current?.enableQrCode;
    };

    const deactivateQrCodeAndNotify = () => {
        formRef.current?.change('enableQrCode', false);
        confirmAlert({
            message:
                'Changes were made to this template, so the active QR code has been deactivated. Once you are done with your changes, please reactivate the QR code before saving.',
            buttons: [
                {
                    label: 'OK'
                }
            ]
        });
    };

    return (
        <ModalDialog
            isOpen={isOpenCustomDocumentForm}
            closeModal={handleCloseModal}
            shouldCloseOnOverlayClick={false}
            className={cx(
                'document-centre-modal',
                'document-modal-dialog',
                'document-no-address-modal-dialog',
                'custom-document',
                'tenant'
            )}
            customHeader={true}
            customHeaderContent={customHeaderContent}
            // Quick fix for templates being cut off by modal
            allowOverflow={true}
            shouldBlockBodyScroll
        >
            {currentDocument &&
                currentDocument.client &&
                (currentDocument.status === LEASE_STATUS_AWAITING_COMPLETION ||
                    currentDocument.status === LEASE_STATUS_SENT_SIGNING) && (
                    <>
                        {currentDocument.confirmationType === SIGN && (
                            <DocumentSignatures
                                className={cx('custom-document__signatures', {
                                    'full-width': !isMobileView
                                })}
                                label="Recipient"
                                note={
                                    !!currentDocument.enforceSequentialSigning && (
                                        <Note
                                            title="Signing Order has been enforced."
                                            type={NoteType.INFO}
                                            className="agreement-signature__sequential_signing__note"
                                        >
                                            Your recipients can only start signing their documents until the previous
                                            signer has completed their part.
                                        </Note>
                                    )
                                }
                            />
                        )}
                    </>
                )}
            {isDocumentTemplateReadOnlyMode(documentEditMode) && (
                <Note type={NoteType.WARNING} className="readOnlyWarning">
                    You can't edit this template, but you can create a copy by cloning using the 3 dots at the top of
                    the screen
                </Note>
            )}
            {currentDocument &&
                (isDocumentStatusDraft(currentDocument.status) ||
                    isDocumentStatusSentForSigning(currentDocument.status) ||
                    !isCompletionListV2Enabled) && (
                    <Form
                        initialValues={formData}
                        validate={() => {
                            if (backendErrors) {
                                return backendErrors;
                            }
                        }}
                        onSubmit={handleSubmit}
                        mutators={{ ...arrayMutators }}
                    >
                        {({ handleSubmit, values, form, dirtyFields, touched }) => {
                            const numberOfFormClients = values.clients?.length ?? 0;
                            const isCustomPlaceholdersDirty = !!dirtyFields.customPlaceholders;
                            if (
                                isCustomPlaceholdersDirty &&
                                isCustomPlaceholdersDirtyRef.current !== isCustomPlaceholdersDirty
                            ) {
                                isCustomPlaceholdersDirtyRef.current = isCustomPlaceholdersDirty;
                            }

                            const hasSignerPlaceholders = values.customPlaceholders?.some(
                                placeholder => placeholder.respondentType === CustomPlaceholderRespondentType.CLIENT
                            );

                            const shouldDisableAddingRecipients =
                                hasSignerPlaceholders &&
                                !isMultipleRecipientsClientPlaceholdersEnabled &&
                                values.clients.length > 0;

                            // set the values to the ref so that the close modal can access these values
                            formValuesRef.current = values;
                            if (!formRef.current) {
                                formRef.current = form;
                            }

                            return (
                                <>
                                    {currentDocument && (
                                        <>
                                            <PdfViewer
                                                isOpen={shouldShowPdfViewer}
                                                close={closePdfViewer}
                                                customDoc={currentDocument}
                                                selectedUploadedDocId={selectedUploadedDocId}
                                                setSelectedUploadedDocId={setSelectedUploadedDocId}
                                                agencyDetails={agencyDetailsForPdfViewer}
                                                onSaveSuccess={handlePdfViewerSaveSuccess}
                                                uploadedDocumentStateItems={uploadedDocumentStateItems}
                                                onCreateWebviewerInstance={addUploadedDocumentStateItem}
                                                onUpdateAnnotationCount={handleUpdateAnnotationCount}
                                                defaultOpenSidebarItems={defaultOpenSidebarItems}
                                                numberOfFormClients={numberOfFormClients}
                                                onWebViewerInitialised={uploadedDocumentId =>
                                                    setIsWebViewerInitialised(uploadedDocumentId, true)
                                                }
                                            />
                                            {isSavingForPdfViewer && (
                                                <div className="pdftron-loader">
                                                    <div
                                                        data-test="pdftron-loading-spinner"
                                                        className="pdftron-loader__spinner"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <form
                                        noValidate
                                        onSubmit={values => handleSubmit(values)}
                                        id="create-custom-doc-form"
                                    >
                                        <FormSpy
                                            subscription={{ values: true }}
                                            onChange={() => handleFormDirtyChange(form)}
                                        />
                                        {documentCreated && (
                                            <React.Fragment>
                                                <br />
                                                {isReadOnly === false && (
                                                    <FormRadioGroupButton
                                                        label="Delivery type"
                                                        labelClassName="custom-document__subheading"
                                                        name="deliveryType"
                                                        data={[
                                                            {
                                                                label: 'SMS',
                                                                value: DOCUMENT_DELIVERY_TYPE_SMS,
                                                                icon: SmsDeliveryV2Icon
                                                            },
                                                            {
                                                                label: 'Email',
                                                                value: DOCUMENT_DELIVERY_TYPE_EMAIL,
                                                                icon: EmailDeliveryV2Icon
                                                            },
                                                            isQrCodeSharingActive &&
                                                                isDocumentTemplateMode(documentEditMode) && {
                                                                    label: 'QR Code/Link',
                                                                    value: DOCUMENT_DELIVERY_TYPE_QR_CODE,
                                                                    icon: QrCodeIcon
                                                                }
                                                        ].filter(Boolean)}
                                                        required={true}
                                                        validateOnTouch={false}
                                                        value={values.deliveryType}
                                                    />
                                                )}
                                                {/* Hide section if the agreement is in (awaiting_completion, complete) or ready only mode */}
                                                {isReadOnly === false && (
                                                    <div
                                                        data-tip={
                                                            hasAnnotations
                                                                ? 'Please remove the signature placeholders below to change this.'
                                                                : undefined
                                                        }
                                                        data-for="confirmation-type-tooltip"
                                                    >
                                                        <FormRadioGroupButton
                                                            label={'Acknowledgement type'}
                                                            labelClassName="custom-document__subheading"
                                                            name="confirmationType"
                                                            data={[
                                                                {
                                                                    label: 'Sign',
                                                                    value: SIGN,
                                                                    icon: SignResponseIcon,
                                                                    disabled: hasAnnotations,
                                                                    onClick: () => setCurrentConfirmationType(SIGN),
                                                                    tooltipText:
                                                                        'This can be used to send multiple docs at one time',
                                                                    showTooltip: !hasAnnotations
                                                                },
                                                                {
                                                                    label: 'Acknowledge',
                                                                    value: ACKNOWLEDGE,
                                                                    icon: AcknowledgeResponseIcon,
                                                                    disabled:
                                                                        hasAnnotations ||
                                                                        uploadedDocumentsList?.length > 1,
                                                                    onClick: () =>
                                                                        setCurrentConfirmationType(ACKNOWLEDGE),
                                                                    tooltipText:
                                                                        'This can be used for sending only one document at a time',
                                                                    showTooltip: !hasAnnotations
                                                                }
                                                            ]}
                                                            required={true}
                                                            validateOnTouch={false}
                                                            value={values.confirmationType}
                                                        />
                                                    </div>
                                                )}
                                                {hasAnnotations && (
                                                    <ReactTooltip
                                                        id="confirmation-type-tooltip"
                                                        class="tooltip"
                                                        place="bottom"
                                                        effect="solid"
                                                        globalEventOff="wheel"
                                                    />
                                                )}
                                                {currentDocument &&
                                                    (isDocumentStatusDraft(currentDocument.status) ||
                                                        isDocumentStatusSentForSigning(currentDocument.status)) && (
                                                        <React.Fragment>
                                                            <div className="flex space-between">
                                                                <h2 className="custom-document__subheading">
                                                                    Recipient details
                                                                </h2>
                                                            </div>
                                                            {isDocumentStatusDraft(currentDocument.status) &&
                                                            values.deliveryType === DOCUMENT_DELIVERY_TYPE_QR_CODE ? (
                                                                <Note
                                                                    title="No Recipient details needed"
                                                                    type={NoteType.INFO}
                                                                >
                                                                    Recipient details are not required. Simply share the
                                                                    document via QR Code/Link, and the Recipient will
                                                                    provide their Name and Email Address during the
                                                                    signing process.
                                                                </Note>
                                                            ) : (
                                                                <RecipientSectionV2
                                                                    document={currentDocument}
                                                                    documentsCount={uploadedDocumentsList.length}
                                                                    shouldDisableAddingRecipients={
                                                                        shouldDisableAddingRecipients
                                                                    }
                                                                    isSequentialSigningEnabled={
                                                                        isSequentialSigningEnabled
                                                                    }
                                                                    hasSignerPlaceholders={hasSignerPlaceholders}
                                                                    showPhoneInput={
                                                                        values.deliveryType ===
                                                                        DOCUMENT_DELIVERY_TYPE_SMS
                                                                    }
                                                                    isReadOnly={isReadOnly}
                                                                    isReorderEnabled={
                                                                        !!values.enforceSequentialSigning &&
                                                                        values.clients.length > 1
                                                                    }
                                                                    onReorderClients={(fromIndex, toIndex) => {
                                                                        reorderClientAnnotations(
                                                                            fromIndex,
                                                                            toIndex,
                                                                            uploadedDocumentStateItems,
                                                                            setUploadedDocumentsList
                                                                        );
                                                                    }}
                                                                    setIsSubmitting={setIsSubmitting}
                                                                    uploadedDocumentStateItems={
                                                                        uploadedDocumentStateItems
                                                                    }
                                                                    isTemplate={isDocumentTemplateMode(documentEditMode)}
                                                                />
                                                            )}
                                                            <br />
                                                            <h2 className="custom-document__subheading">
                                                                Document introduction statement
                                                            </h2>
                                                            <FormTextMultiline
                                                                name="descriptionText"
                                                                label="Introduce your document"
                                                                disabled={isReadOnly}
                                                                isChatGpt={true}
                                                                gpt_fieldName="descriptionText"
                                                                gpt_isDocument={true}
                                                                gpt_documentId={customDocumentId}
                                                            />
                                                            <div className="form-checkbox">
                                                                <CheckboxCheck
                                                                    name="includeDescriptionTextInEmail"
                                                                    label="Include this statement in the introductory email"
                                                                    disabled={isReadOnly}
                                                                />
                                                            </div>
                                                            {!isReadOnly && !isMobileView && (
                                                                <div className="document-upload-container">
                                                                    <h2 className="custom-document__subheading">
                                                                        Upload file
                                                                    </h2>
                                                                    <UploadADocUpload
                                                                        isSubmitting={isSubmitting}
                                                                        values={values}
                                                                        clients={values.clients}
                                                                        setDocumentUploadProgress={
                                                                            setDocumentUploadProgress
                                                                        }
                                                                        uploadEndPoint={`api/document/documents-custom/${customDocumentId}`}
                                                                        viewDocument={docId =>
                                                                            viewUploadedDocumentFromDocument(
                                                                                currentDocument.id,
                                                                                docId
                                                                            )
                                                                        }
                                                                        deleteDocument={(
                                                                            updatedFilesList,
                                                                            deletedDocumentId
                                                                        ) => {
                                                                            deleteDocumentAndSave(
                                                                                form,
                                                                                updatedFilesList,
                                                                                deletedDocumentId
                                                                            );
                                                                        }}
                                                                        addDocuments={files => {
                                                                            addDocumentsToListAndSave(files, values);
                                                                        }}
                                                                        reorderDocumentsList={reorderDocumentList}
                                                                        addAnnotationsForFile={(file, files) => {
                                                                            setDefaultOpenSidebarItems([
                                                                                SidebarAccordionItems.CLIENTS,
                                                                                SidebarAccordionItems.DOCUMENTS,
                                                                                SidebarAccordionItems.CUSTOM_PLACEHOLDERS
                                                                            ]);
                                                                            addAnnotationsForFile(file, files, values);
                                                                        }}
                                                                        handleClearSignaturePlaceholders={uploadedDocumentId => {
                                                                            handleClearSignaturePlaceholders(
                                                                                values,
                                                                                uploadedDocumentId
                                                                            );
                                                                        }}
                                                                        handleClearSignaturePlaceholdersOnAllDocs={() =>
                                                                            handleClearSignaturePlaceholders(values)
                                                                        }
                                                                        uploadedDocuments={uploadedDocumentsList}
                                                                        backendError={backendError}
                                                                        documentUploadProgress={documentUploadProgress}
                                                                        hideUploader={isReadOnly}
                                                                        disabled={
                                                                            values.confirmationType ===
                                                                                ACKNOWLEDGE_CONFIRMATION_TYPE &&
                                                                            uploadedDocumentsList.length >= 1
                                                                        }
                                                                        disabledTooltipText="Can only send one file when using the acknowledge page"
                                                                    />
                                                                </div>
                                                            )}
                                                            {isMobileView && (
                                                                <div className="custom-document__section">
                                                                    <h2 className="custom-document__subheading">
                                                                        Documents
                                                                    </h2>
                                                                    <div className="custom-document__document-list--mobile">
                                                                        {uploadedDocumentsList.map(
                                                                            (uploadedDocument, index) => {
                                                                                return (
                                                                                    <UploadedDocumentMobile
                                                                                        documentName={
                                                                                            uploadedDocument.document
                                                                                                .documentName
                                                                                        }
                                                                                        key={index}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div id="annotation-viewer-anchor" />
                                                            {isForceSigningOrderEnabled &&
                                                                uploadedDocumentsList &&
                                                                uploadedDocumentsList.length > 1 && (
                                                                    <div className="form-checkbox-signing-order">
                                                                        <CheckboxCheck
                                                                            name="enforceSigningOrder"
                                                                            label="Enforce signing order of documents"
                                                                            disabled={isReadOnly}
                                                                        />
                                                                    </div>
                                                                )}
                                                            {isDocumentStatusSentForSigning(currentDocument.status) && (
                                                                <div className="button-footer-docs">
                                                                    <h2 className="custom-document__subheading">
                                                                        Documents
                                                                    </h2>
                                                                    {renderUploadedDocuments()}
                                                                </div>
                                                            )}
                                                            {values.confirmationType !==
                                                                ACKNOWLEDGE_CONFIRMATION_TYPE && (
                                                                <CustomPlaceholderFormSection
                                                                    deleteAnnotationsForCustomPlaceholder={customPlaceholderId => {
                                                                        deleteAnnotationsForCustomPlaceholder(
                                                                            customPlaceholderId,
                                                                            uploadedDocumentStateItems
                                                                        );
                                                                    }}
                                                                    removeCustomPlaceholderValidation={index => {
                                                                        if (
                                                                            backendErrors?.customPlaceholders?.[index]
                                                                        ) {
                                                                            setBackendErrors(errors => {
                                                                                errors.customPlaceholders.splice(
                                                                                    index,
                                                                                    1
                                                                                );
                                                                                return errors;
                                                                            });
                                                                        }
                                                                    }}
                                                                    canOpenPdfViewer={!!uploadedDocumentsList.length}
                                                                    handleOpenPdfViewer={() => {
                                                                        setDefaultOpenSidebarItems([
                                                                            SidebarAccordionItems.CUSTOM_PLACEHOLDERS
                                                                        ]);
                                                                        addAnnotationsForFile(
                                                                            uploadedDocumentsList[0],
                                                                            uploadedDocumentsList,
                                                                            values
                                                                        );
                                                                    }}
                                                                    isReadOnly={isReadOnly}
                                                                    showCustomPlaceholderWarning={
                                                                        hasUpdatedCustomPlaceholdersSinceOpeningPdf
                                                                    }
                                                                    onEditCustomPlaceholderValues={count => {
                                                                        if (
                                                                            !hasUpdatedCustomPlaceholdersSinceOpeningPdf &&
                                                                            count > 0
                                                                        ) {
                                                                            setHasUpdatedCustomPlaceholdersSinceOpeningPdf(
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                    isMobileView={isMobileView}
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                {currentDocument &&
                                                    (isDocumentAwaitingCompletion(currentDocument.status) ||
                                                        isDocumentComplete(currentDocument.status)) && (
                                                        <FlkAPdfSummary
                                                            form={form}
                                                            values={values}
                                                            currentDocument={currentDocument}
                                                            SIGN={SIGN}
                                                            ACKNOWLEDGE={ACKNOWLEDGE}
                                                            renderUploadedDocuments={renderUploadedDocuments}
                                                            documentEditMode={documentEditMode}
                                                            isMobileView={isMobileView}
                                                        />
                                                    )}
                                                {currentDocument &&
                                                    (isDocumentStatusDraft(currentDocument.status) ||
                                                        isDocumentStatusSentForSigning(currentDocument.status)) && (
                                                        <ClientAcknowledgement
                                                            shouldShowMandatoryStatement={
                                                                values.confirmationType === SIGN
                                                            }
                                                            isReadOnly={isReadOnly}
                                                            className="custom-document__clientAcknowledgementContainer"
                                                        />
                                                    )}
                                                {isListNowFeatureEnabled &&
                                                    isListNowEnabled &&
                                                    isDocumentStatusDraft(currentDocument.status) && (
                                                        <div>
                                                            <div className="custom-document__subheading-container">
                                                                <h2 className="custom-document__subheading">
                                                                    Linked Partner(s)
                                                                </h2>
                                                                <TooltipIcon
                                                                    type={TooltipIconType.INFO}
                                                                    iconSize={IconSize.SMALL}
                                                                    className="toggle-tooltip"
                                                                >
                                                                    Enhance your workflow by linking this document to
                                                                    your active Linked Partner(s)
                                                                </TooltipIcon>
                                                            </div>
                                                            <ListNow />
                                                        </div>
                                                    )}
                                                {isListNowFeatureEnabled &&
                                                    isListNowEnabled &&
                                                    isBusinessUserAccount(loggedInUser.accountType) &&
                                                    currentDocument?.listNow?.enabled &&
                                                    isDocumentStatusSentForSigning(currentDocument.status) && (
                                                        <LinkedPartnersSummary currentDocument={currentDocument} />
                                                    )}

                                                {isQrCodeSharingActive &&
                                                    isDocumentTemplateMode(documentEditMode) &&
                                                    values.deliveryType === DOCUMENT_DELIVERY_TYPE_QR_CODE && (
                                                        <>
                                                            <QrCodeSharing
                                                                enableQrCode={values.enableQrCode}
                                                                templateId={currentDocument.id}
                                                            />
                                                            <DeactivateQrCodeListeners
                                                                onChange={() => {
                                                                    if (shouldDeactivateQrCode()) {
                                                                        deactivateQrCodeAndNotify();
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    )}

                                                {!isDocumentTemplateReadOnlyMode(documentEditMode) && (
                                                    <FlkAPdfFooter
                                                        form={form}
                                                        values={values}
                                                        currentDocument={currentDocument}
                                                        documentsList={uploadedDocumentsList}
                                                        isSubmitting={isSubmitting}
                                                        isSubmitFail={isSubmitFail}
                                                        isSubmitSuccessful={isSubmitSuccessful}
                                                        documentEditMode={documentEditMode}
                                                        customDocumentId={customDocumentId}
                                                        setBackendErrors={setBackendErrors}
                                                        getErrorFromResponse={getErrorFromResponse}
                                                        closeModal={closeModal}
                                                        setIsDirty={setIsDirty}
                                                        isSending={isSending}
                                                        isSendFail={isSendFail}
                                                        isSendSuccessful={isSendSuccessful}
                                                        setIsSending={setIsSending}
                                                        setIsSendFail={setIsSendFail}
                                                        setIsSendSuccessful={setIsSendSuccessful}
                                                        isTemplate={isDocumentTemplateMode(documentEditMode)}
                                                        pdfGenerated={pdfGeneratedState || PDF_GEN_COMPLETE}
                                                        saveDocumentAndRedirectToAnnotationViewer={() => {
                                                            const clonedalues = cloneDeep(values);
                                                            const data = getFormValuesWithExecutionType(
                                                                clonedalues,
                                                                uploadedDocumentsList
                                                            );
                                                            data.uploadedDocuments = uploadedDocumentsList;
                                                            return saveDocumentAndRedirectToAnnotationViewer(
                                                                data,
                                                                false,
                                                                data.uploadedDocuments[0]
                                                            );
                                                        }}
                                                        resendWaitSeconds={resendWaitSeconds}
                                                        areAllDocumentsInitialised={areAllDocumentsInitialised}
                                                        isMobileView={isMobileView}
                                                        setIsOpenResendModal={setIsOpenResendModal}
                                                        isOpenResendModal={isOpenResendModal}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}

                                        {isOpenConfirmAlert && (
                                            <UnevenSignaturesConfirmModal
                                                values={values}
                                                isOpen={isOpenConfirmAlert}
                                                closeModal={() => setIsOpenConfirmAlert(false)}
                                                annotationsCount={annotationsCount}
                                                isTemplate={isDocumentTemplateMode(documentEditMode)}
                                                isSubmitting={isSubmitting}
                                                isSending={isSending}
                                                onEdit={() => {
                                                    const data = cloneDeep(values);
                                                    // add documents to values
                                                    data.uploadedDocuments = uploadedDocumentsList;
                                                    setIsSubmitting(true);
                                                    return saveDocumentAndRedirectToAnnotationViewer(
                                                        data,
                                                        false,
                                                        data.uploadedDocuments[0]
                                                    );
                                                }}
                                                onSave={() => {
                                                    const data = cloneDeep(values);
                                                    return handleSendForSigning(data).finally(() => {
                                                        // reset to type save in case the user submits with enter
                                                        form.change('submitType', FORM_SUBMIT_TYPE_SAVE);
                                                        setIsDirty(false);
                                                        setIsOpenConfirmAlert(false);
                                                    });
                                                }}
                                            />
                                        )}
                                    </form>
                                </>
                            );
                        }}
                    </Form>
                )}
            {isCompletionListV2Enabled &&
                currentDocument &&
                (isDocumentAwaitingCompletion(currentDocument.status) ||
                    isDocumentComplete(currentDocument.status)) && (
                    <UploadADocSummary
                        currentDocument={currentDocument}
                        SIGN={SIGN}
                        ACKNOWLEDGE={ACKNOWLEDGE}
                        renderUploadedDocuments={renderUploadedDocuments}
                        documentEditMode={documentEditMode}
                        isMobileView={isMobileView}
                    />
                )}
            {!documentCreated && (
                <FlkAPdfCreate
                    closeModal={() => {
                        dispatch(closeCustomDocumentModal());
                        if (isMobileView) {
                            history.push('/welcome');
                        }
                    }}
                    creatingDocument={creatingDocument}
                    isTemplate={isDocumentTemplateMode(documentEditMode) || selectedTemplate}
                    loggedInUser={loggedInUser}
                    setCreatingDocument={setCreatingDocument}
                    setCustomDocumentId={setCustomDocumentId}
                    setDocumentCreated={setDocumentCreated}
                    clonedSelectedTemplate={selectedTemplate}
                    isMobileView={isMobileView}
                />
            )}
            {isOpenEditRecipientsModal && (
                <EditRecipientsModal
                    isOpen={isOpenEditRecipientsModal}
                    closeModal={() => setIsOpenEditRecipientsModal(false)}
                    doc={currentDocument}
                    openResendModal={() => setIsOpenResendModal(true)}
                />
            )}
        </ModalDialog>
    );
}
export default memo(FlkAPdfForm);
