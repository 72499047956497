import React from 'react';
import { useDispatch } from 'react-redux';
import ModalDialog from '../../../../common/components/ModalDialog.js';
import { Form } from 'react-final-form';
import { UploadADoc } from '@app/types/UploadADoc.js';
import TextInputField from '../buildADoc/components/TextInput';
import { FormPhone } from '@app/components/form/FormPhone';
import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';
import { DOCUMENT_DELIVERY_TYPE_SMS } from '@app/config';
import Button from '../../../../common/components/Button.js';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { addOrReplaceDocumentInList, setCurrentDocument } from '../../../../actions/document.js';

import cx from 'classnames';
import styles from './EditRecipientsModal.module.scss';

type EditRecipientsModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    doc: UploadADoc;
    openResendModal: () => void;
};

type Client = {
    name: string;
    email: string;
    phone?: string;
};

const EditRecipientsModal: React.FC<EditRecipientsModalProps> = ({ isOpen, closeModal, doc, openResendModal }) => {
    const dispatch = useDispatch();

    const editRecipientsMutation = useMutation(
        (data: Client[]) => {
            return axios.post(`api/document/custom-document/${doc.id}/edit-recipients`, data);
        },
        {
            onSuccess: response => {
                dispatch(addOrReplaceDocumentInList(response.data.doc));
                dispatch(setCurrentDocument(response.data.doc));
                closeModal();
                openResendModal();
            }
        }
    );

    const submitValues = async (values: Client[]) => {
        try {
            await editRecipientsMutation.mutateAsync(values);
        } catch (err) {
            if (err.response?.data?.errors?.client?.clients) {
                return err.response.data.errors.client;
            }
        }
    };

    return (
        <ModalDialog title="Edit Recipients" isOpen={isOpen} closeModal={closeModal} shouldCloseOnOverlayClick={false}>
            <Form onSubmit={submitValues} initialValues={{ clients: doc?.client?.clients || [] }}>
                {({ handleSubmit }) => {
                    return (
                        <form id="update-tenant-detail-form" onSubmit={handleSubmit} noValidate className={styles.form}>
                            <div className={styles.recipients}>
                                {doc?.client?.clients.map((client, index) => (
                                    <Card
                                        key={index}
                                        style={CardStyles.SQUARE}
                                        shadow={CardShadow.SMALL}
                                        className={styles.fields}
                                    >
                                        {!!client.signature && (
                                            <p className={styles.error}>
                                                The recipient has already signed the document.
                                            </p>
                                        )}
                                        <TextInputField
                                            name={`clients[${index}].name`}
                                            label="Recipient Name"
                                            isRequired
                                            placeholder="Enter full name"
                                            className={styles.nameField}
                                            validateOnTouch={false}
                                            disabled={true}
                                        />
                                        <TextInputField
                                            name={`clients[${index}].email`}
                                            label="Email"
                                            isRequired
                                            placeholder="Enter email address"
                                            className={styles.emailField}
                                            validateOnTouch={false}
                                            disabled={!!client.signature}
                                        />
                                        {doc.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS && (
                                            <FormPhone
                                                name={`clients[${index}].phone`}
                                                label="Phone"
                                                required
                                                className={styles.phoneField}
                                                validateOnTouch={false}
                                                dataTest={`clients[${index}].phone`}
                                                disabled={!!client.signature}
                                            />
                                        )}
                                    </Card>
                                ))}
                            </div>
                            <div className={styles.footer}>
                                <Button
                                    disabled={editRecipientsMutation.isLoading}
                                    isLoading={editRecipientsMutation.isLoading}
                                    primary
                                    className={styles.submitButton}
                                >
                                    Save changes
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default EditRecipientsModal;
