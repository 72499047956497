import React, { memo, useState } from 'react';

import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { lowerCase } from 'lodash';

import * as dashboard from '../../actions/dashboard';
import '../../sass/popupLeaseMore.scss';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
    cancelSigningDocumentAction,
    cloneDocumentSuccess,
    removeDocumentInList,
    documentListReset,
    closeAllDocumentModals
} from '../../actions/document';
import { useRollbarProvider } from '../providers/RollbarProvider';
import { isUserRoleAdmin } from '../../selectors/user';
import { confirmAlert } from 'react-confirm-alert';
import * as PropTypes from 'prop-types';
import { PreLoaderSmall } from '../../common/components/PreLoaderSmall';
import { CANCEL_SIGNING_MESSAGE } from '../../utils/labelUtils';
import AppHistory from '../../AppHistory';
import { disableLeaseDeletion } from '../../utils/agreementUtils';
import Tooltip from '../../common/components/tooltips/Tooltip';
import EditSharpIcon from '@flk-mui-icons/EditSharp';

function CloneButton({ cloneDocument, isCloning }) {
    return (
        <div className="icon-block" onClick={e => cloneDocument(e, true)}>
            <i className="icon icon-duplicate" />
            <p className="popup-draft-p">Clone document</p>
            {isCloning && <PreLoaderSmall />}
        </div>
    );
}

const DeleteButton = ({ deleteDocument, docStatus, isDisabled }) => {
    return (
        <>
            <div
                data-for="delete"
                data-tip={`Team members aren't allowed to delete agreements in ${lowerCase(
                    docStatus
                )}. Please contact your admin to assist`}
                className={`icon-block disabled-${isDisabled}`}
                onClick={e => {
                    e.stopPropagation();
                    if (!isDisabled) {
                        deleteDocument(e);
                    }
                }}
            >
                <i className="icon icon-trash" />
                <p className="popup-draft-p">Delete document</p>
            </div>
            {isDisabled && <Tooltip place="bottom" effect="solid" id="delete" />}
        </>
    );
};

CloneButton.propTypes = { cloneDocument: PropTypes.func };
DeleteButton.propTypes = { deleteDocument: PropTypes.func };

const PopupFlowDocumentsMore = props => {
    const dispatch = useDispatch();
    const Rollbar = useRollbarProvider();
    const isUserAdmin = useSelector(isUserRoleAdmin);

    const [isCloning, setIsCloning] = useState(false);

    const docTypeToServer = {
        rent_increase_agreement: 'rent-increase',
        rent_reduction_agreement: 'rent-relief',
        termination_notice: 'termination-notice'
    };

    const handleClickOutside = event => {
        // close the list when list is open and clicked outside the list
        // ignore this if user click more-action button
        if ((!event?.path || event?.path[0].className !== 'more-action') && props.morePopupListAgreements) {
            props.closeMorePopupList();
        }
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const cloneDocumentRequest = documentId => {
        return axios.post(`/api/document/clone/${documentId}`);
    };

    const cloneDocument = (e, updateDocumentList) => {
        e.stopPropagation();
        if (isCloning) {
            return;
        }

        setIsCloning(true);
        dispatch(documentListReset());
        return cloneDocumentRequest(props.doc.id)
            .then(({ data: { doc } }) => {
                props.closeMorePopupList();
                if (updateDocumentList) {
                    AppHistory.push(`/dashboard/document/${doc.id}`);
                    dispatch(cloneDocumentSuccess());
                }
            })
            .catch(error => {
                Rollbar.error(
                    'Failed to clone Flow document',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
            })
            .finally(() => setIsCloning(false));
    };

    /**
     * Cancel signing of document
     * After canceling lease agreement will be moved to the DRAFT status.
     * All signatures will also be removed
     */
    function cancelSigningWithConfirmation(e) {
        e.stopPropagation();
        confirmAlert({
            title: '',
            message: CANCEL_SIGNING_MESSAGE,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await cancelSigning();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    const cancelSigning = () => {
        dispatch(cancelSigningDocumentAction(props.doc.id));
        dispatch(removeDocumentInList(props.doc));
        props.closePopup();
    };

    const deleteDocumentRequest = documentId => {
        return axios.delete(`/api/document/${documentId}`);
    };

    const deleteDocument = e => {
        e.stopPropagation();
        confirmAlert({
            title: '',
            message: `Are you sure you want to delete this document?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        return deleteDocumentRequest(props.doc.id)
                            .then(() => {
                                props.closeMorePopupList();
                                dispatch(removeDocumentInList(props.doc));
                                dispatch(closeAllDocumentModals());
                            })
                            .catch(error => {
                                Rollbar.error(
                                    'Failed to delete Flow document',
                                    {
                                        error_message: error.message,
                                        status: 'error',
                                        env: ENVIRONMENT
                                    },
                                    error
                                );
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    const editSigners = e => {
        e.stopPropagation();
        props.editSigners();
        props.closeMorePopupList();
    };

    const { morePopupInfoAgreement, agreementStatus, morePopupListAgreements, isReadOnlyTemplate, signersLabel } =
        props;

    return (
        <div
            ref={ref}
            className={cx(
                morePopupInfoAgreement
                    ? 'popup-draft-create'
                    : morePopupListAgreements
                      ? 'popup-draft-create create-list'
                      : 'popup-draft-create hide'
            )}
        >
            {agreementStatus === dashboard.LEASE_STATUS_DRAFT && (
                <div>
                    <CloneButton cloneDocument={e => cloneDocument(e, !isReadOnlyTemplate)} isCloning={isCloning} />
                    {!isReadOnlyTemplate && <DeleteButton deleteDocument={e => deleteDocument(e)} />}
                </div>
            )}

            {agreementStatus === dashboard.LEASE_STATUS_SENT_SIGNING && (
                <div>
                    <CloneButton cloneDocument={cloneDocument} isCloning={isCloning} />
                    <div className="icon-block" onClick={e => cancelSigningWithConfirmation(e)}>
                        <i className="icon icon-cancel-sign" />
                        <p className="popup-draft-p">Move to drafts</p>
                    </div>
                    {signersLabel && (
                        <div className="icon-block" onClick={e => editSigners(e)}>
                            <EditSharpIcon className="icon icon-edit-tenant" />
                            <p className="popup-draft-p">Edit {signersLabel}</p>
                        </div>
                    )}
                </div>
            )}

            {agreementStatus === dashboard.LEASE_STATUS_AWAITING_COMPLETION && (
                <div>
                    <CloneButton cloneDocument={cloneDocument} isCloning={isCloning} />
                    <DeleteButton
                        deleteDocument={e => deleteDocument(e)}
                        isDisabled={disableLeaseDeletion(isUserAdmin, props.doc.status)}
                        docStatus={props.doc.status}
                    />
                </div>
            )}

            {agreementStatus === dashboard.LEASE_STATUS_COMPLETE && (
                <div>
                    <CloneButton cloneDocument={cloneDocument} isCloning={isCloning} />
                    <DeleteButton
                        deleteDocument={e => deleteDocument(e)}
                        isDisabled={disableLeaseDeletion(isUserAdmin, props.doc.status)}
                        docStatus={props.doc.status}
                    />
                </div>
            )}
        </div>
    );
};

PopupFlowDocumentsMore.defaultProps = {
    showResendButton: true
};

export default memo(PopupFlowDocumentsMore);
